<template>
    <div class="flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm>
                                    <CRow class="mb-3">
                                        <CCol col="9" class="text-left">
                                            <h2><b>Reset Password</b></h2>
                                            <p class="text-muted">Masukkan password baru anda</p>
                                        </CCol>
                                        <CCol col="3" class="text-left">
                                            <img src="img/login.png" alt="" class="img-responsive" style="width: 75px; float: right;">
                                        </CCol>
                                    </CRow>
                                    <CInput placeholder="Password" type="password" v-model="password">
                                        <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                                    </CInput>
                                    <CInput placeholder="Konfirmasi Password" type="password" v-model="password_confirmation">
                                        <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                                    </CInput>
                                    <CRow>
                                        <CCol col="12" class="text-left">
                                            <CButton class="btn btn-primary btn-block" v-on:click="submitResetPassword">Kirim</CButton>
                                        </CCol>
                                        <!-- <CCol col="6" class="text-right">
                                            <router-link :to="{path: 'login'}" color="link" class="px-0" >Login</router-link>
                                        </CCol> -->
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<script>
    export default {
        name: "LupaPassword",
        data(){
            return{
                // id: this.$route.query.id,
                // token: this.$route.query.token,
                email: this.$route.query.email,
                userdata: [],
                password: '',
                password_confirmation: '',
            }
        },
        methods: {
            submitResetPassword(saveas){
                let self = this;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Mohon tunggu sebentar...",
                    showConfirmButton: false,
                });
                var rows_benih = [];
                let required_form = new Promise(function(resolve, reject) {
                    // Cek Required
                    if (self.password != self.password_confirmation) {
                        Swal.fire({
                            icon: 'error',
                            text: 'Password dan Konfirmasi Password harus sama'
                        });
                        resolve("stop");
                    }
                    if (self.password.length <= 7) {
                        Swal.fire({
                            icon: 'error',
                            text: 'Password harus terdiri minimal 8 huruf dan angka'
                        });
                        resolve("stop");
                    }
                    resolve("next");
                });
                required_form.then(
                    function (response) {
                        if (response == 'stop') {
                            return false;
                        }
                        var FormData = require('form-data');
                        var data = new FormData();
                        // data.append('email', self.userdata?.user?.email);
                        // data.append('token', self.userdata?.token);
                        data.append('email', self.email);
                        data.append('password', self.password);
                        data.append('password_confirmation', self.password_confirmation);
                        var config = {
                          method: 'post',
                          url: self.apiLink + "api/update-password",
                          data : data
                        };
                        axios(config)
                        .then(function (response) {
                        var res_ajukan = response.data;
                        var res_ajukan_data = res_ajukan.data;
                        // console.log(res_ajukan);
                          if (res_ajukan.meta.code != "200") {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: res_ajukan.data.message
                                });
                            }
                            else{
                                let timerInterval;
                                Swal.fire({
                                    icon: "success",
                                    title: "Berhasil",
                                    text: "Anda akan diarahkan ke halaman login",
                                    timer: 2000,
                                    timerProgressBar: true,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    didOpen: () => {
                                        timerInterval = setInterval(() => {
                                            const content = Swal.getContent();
                                            if (content) {
                                                const b = content.querySelector("b");
                                                if (b) {
                                                    b.textContent = Swal.getTimerLeft();
                                                }
                                            }
                                        }, 100);
                                    },
                                    willClose: () => {
                                        clearInterval(timerInterval);
                                    },
                                }).then((result) => {
                                    /* Read more about handling dismissals below */
                                    if (result.dismiss === Swal.DismissReason.timer) {
                                        self.$router.push({name: 'Login'});
                                    }
                                });
                            }
                        }).catch((error) => {
                            console.log(error.response);
                            if (error) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Gagal",
                                    html: error.response.data.data.message,
                                });
                            }
                        });
                    }
                );
            },
        },
        created(){
            // axios.get(this.apiLink + "api/get-user", {
            //     params: {
            //         id: this.id,
            //         token: this.token,
            //     },
            //     headers: {
            //         Authorization: "Bearer " + this.access_token,
            //     },
            // })
            // .then((response) => {
            //     var res_userdata = response.data;
            //     var res_userdata_data = res_userdata.data;
            //     if (res_userdata.meta.code != "200") {
            //         Swal.fire({
            //             icon: 'error',
            //             title: 'Oops...',
            //             text: res_userdata.data.message
            //         });
            //     }
            //     else{
            //         this.userdata = res_userdata_data;
            //         console.log(this.userdata);
            //     }
            // });
        }
    };
</script>